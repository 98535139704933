<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card elevation='0'>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row no-gutters>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Description*</p>
                  <v-text-field
                    v-model='description'
                    :error='descriptionError'
                    :messages='descriptionMessage'
                    :rules="noSlashRule"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="6" sm="6" xs="6" md="6" lg="6" xl="6">
                  <p class="font-weight-bold">Type of vote*</p>
                  <v-autocomplete
                    v-model="typeVote"
                    :items="typeVoteArray"
                    class="ml-2"
                    outlined
                    required
                    dense
                    :error="errorTypeVote"
                    :messages="messageTypeVote"
                  ></v-autocomplete>
                </v-col> -->
                <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
                  <p class='font-weight-bold'>Document*</p>
                </v-col>
                <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
                  <v-file-input
                    v-model='file'
                    :error='fileError'
                    :messages='fileMessage'
                    accept=''
                    dense
                    outlined
                    placeholder='Upload your document'
                    prepend-icon=''
                    prepend-inner-icon='mdi-paperclip'
                    show-size
                    @change='selectFile'
                  ></v-file-input>
                </v-col>

                <v-col cols='12' lg='8' md='10' sm='12' xl='8' xs='12'>
                  <v-btn
                    active-class='black-light'
                    class='black--text text-capitalize ml-2'
                    color='black'
                    outlined
                    style='float: left'
                    @click='download'
                  >
                    <v-icon color='black'>mdi-download</v-icon>
                    <b class='black--text text-capitalize'>Download Xlsx Template</b>
                  </v-btn>
                  <v-btn
                    :loading='loading'
                    active-class='green-ligth'
                    class='green--text text-capitalize ml-2'
                    color='green'
                    outlined
                    style='float: right'
                    @click='saveFile'
                  >
                    <v-icon color='green'>mdi-upload</v-icon>
                    <b class='green--text text-capitalize'>Upload File</b>
                  </v-btn>
                </v-col>
                <div v-if='viewInput' v-frag>
                  <v-col
                    v-for='(itemInput, indexInput) in inputs'
                    :key='indexInput'
                    cols='12'
                    lg='12'
                    md='12'
                    sm='12'
                    xl='12'
                    xs='12'
                  >
                    <v-row>
                      <v-col cols='2' lg='1' md='1' sm='2' xl='1' xs='2'>
                        <v-checkbox
                          v-model='itemInput.check'
                          :disabled='itemInput.disabled'
                        ></v-checkbox>
                      </v-col>
                      <v-col cols='5' lg='5' md='5' sm='5' xl='5' xs='5'>
                        <v-text-field
                          v-model='itemInput.text'
                          :disabled='!itemInput.check'
                          autocomplete='off'
                          class='mt-3'
                          dense
                          outlined
                          readonly
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols='5' lg='6' md='6' sm='5' xl='6' xs='5'>
                        <v-autocomplete
                          v-model='itemInput.value'
                          :chips='itemInput.multiple ? true : false'
                          :deletable-chips='itemInput.multiple ? true : false'
                          :disabled='!itemInput.check'
                          :error='itemInput.error'
                          :items='headers'
                          :messages='itemInput.message'
                          :multiple='itemInput.multiple'
                          :placeholder='itemInput.text'
                          :search-input.sync='itemInput.searchInput'
                          class='mt-3'
                          dense
                          disable-lookup
                          outlined
                          required
                          @input='itemInput.searchInput = null'
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='cancel'
          >
            Cancel
          </v-btn>

          <div v-if='idImportVoting' v-frag>
            <v-btn
              :disabled='loading'
              :loading='loading'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='save'
            >
              Submit
            </v-btn>
            <!-- <v-btn
              large
              color="green"
              class="px-14 mx-4 font-weight-bold white--text"
              :disabled="loading"
              :loading="loading"
              @click="syncFile"
            >
              Sync up
            </v-btn> -->
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import importVoting from '../../../services/importVoting'

export default {
  computed: {
    crud() {
      if (this.$store.getters.crud.dialog) {
        this.initialLoad()
      }
      return this.$store.getters.crud
    },
    idImportVoting() {
      if (this.$store.getters.crud.id) {
        return this.$store.getters.crud.id
      }

      if (this.id) {
        return this.id
      }
      return null
    }
  },
  data: () => ({
    id: null,
    loading: false,
    valid: true,
    headers: [],
    description: '',
    descriptionError: false,
    descriptionMessage: '',
    file: null,
    status: null,
    fileError: false,
    fileMessage: '',
    viewInput: false,
    typeVote: 2,
    typeVoteArray: [
      {text: 'Voting', value: 1},
      {text: 'Register', value: 2}
    ],
    errorTypeVote: false,
    messageTypeVote: '',
    inputs: [],
    noSlashRule: [
      v => !!v || "Field is required",
      v => !v.includes('/') && !v.includes('\\') || "Field must not contain an slash character",
    ]
  }),
  methods: {
    async initialLoad() {
      try {
        if (this.idImportVoting) {
          await this.getHeaders()
          await this.getData()
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async getData() {
      this.loading = true
      try {
        const res = await importVoting.show(this.idImportVoting)
        const temp = res.votingImportHeader
        let aux = res.inputs
        for (let index in temp) {
          for (let y in aux) {
            if (aux[y].text_value == temp[index].text) {
              if (aux[y].multiple) {
                aux[y].value.push(parseInt(temp[index].value))
              } else {
                aux[y].value = parseInt(temp[index].value)
              }
              if (aux[y].value != '') {
                aux[y].check = true
              }
            }
          }
        }
        this.inputs = aux
        this.file = this.fileConvert(res.name)
        this.status = res.status
        this.typeVote = res.type_vote
        this.description = res.description
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async getHeaders() {
      this.loading = true
      try {
        const res = await importVoting.getHeaders(this.idImportVoting)
        if (res.success) {
          this.headers = res.data.data
          this.loading = false
          this.viewInput = true
        } else {
          this.viewInput = false
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        this.viewInput = false
      }
    },
    async save() {
      this.updated()
      // if (this.idCampaign) {
      // this.updated();
      // } else {
      //     this.store();
      // }
    },
    selectFile(file) {
      if (file) {
        this.file = file
      } else {
        this.file = null
      }
    },

    async saveFile() {
      this.loading = true
      this.fileError = false
      this.fileMessage = ''
      this.delimiterError = false
      this.delimiterMessage = ''

      this.descriptionError = false
      this.descriptionMessage = ''
      if (!this.file) {
        this.fileError = true
        this.fileMessage = 'You must select a file'
        this.loading = false
        return
      }
      // if (!this.typeVote) {
      //   this.errorTypeVote = true;
      //   this.messageTypeVote = "You must select a type";
      //   this.loading = false;
      //   return;
      // }
      if (!this.description) {
        this.descriptionError = true
        this.descriptionMessage = 'You must put a description for the import'
        this.loading = false
        return
      }
      try {
        let res
        if (this.idImportVoting) {
          res = await importVoting.update(
            this.idImportVoting,
            this.crud.data.campaing,
            this.typeVote,
            this.description
          )
        } else {
          res = await importVoting.store(
            this.crud.data.campaing,
            this.typeVote,
            this.description
          )
        }
        this.uploadFile(res.data)
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialog()
        // await this.getHeaders();
      } catch (e) {
        this.loading = false
      }
    },

    async uploadFile(id) {
      try {
        importVoting.uploadFile(id, this.file)
      } catch (e) {
        // empty
      }
    },
    async updated() {
      this.loading = true
      try {
        let temp = this.inputs
        let inputCopy = this.inputs
        let aux = []
        let send = true
        for (let index in temp) {
          if (temp[index].check) {
            if (temp[index].multiple) {
              if (temp[index].value.length == 0) {
                inputCopy[index].error = true
                inputCopy[index].message = 'The field is required'
                send = false
              } else {
                inputCopy[index].error = false
                inputCopy[index].message = ''
                send = true
              }
            } else {
              if (typeof temp[index].value != 'number') {
                inputCopy[index].error = true
                inputCopy[index].message = 'The field is required'
                send = false
              } else {
                inputCopy[index].error = false
                inputCopy[index].message = ''
                send = true
              }
            }
          }
          aux.push({
            check: temp[index].check,
            disabled: temp[index].disabled,
            text: temp[index].text,
            text_value: temp[index].text_value,
            multiple: temp[index].multiple,
            value: temp[index].value,
            type: temp[index].type
          })
        }
        if (!send) {
          this.inputs = inputCopy
          this.loading = false
          return
        }
        const res = await importVoting.setHeaders(this.idImportVoting, aux)
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.syncFile()
        this.closeDialog()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async syncFile() {
      this.loading = true
      try {
        const res = await importVoting.syncFile(this.idImportVoting)
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.loading = false
        //   this.closeDialog();
      } catch (e) {
        this.loading = false
      }
    },
    cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.$refs.form.reset()
      this.id = null
      this.file = null
      this.fileError = false
      this.fileMessage = ''
      this.status = null
      this.typeVote = ''
      this.errorTypeVote = false
      this.messageTypeVote = ''
      this.viewInput = false
      this.description = ''
      this.descriptionError = false
      this.descriptionMessage = ''
      this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    },

    fileConvert(value) {
      if (typeof value == 'string' && value != '') {
        return new File([`${value}`], `${value}`, {
          type: 'text/plain'
        })
      } else {
        return ''
      }
    },
    download() {
      try {
        importVoting.download()
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
