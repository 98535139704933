<template>
  <div v-frag>
    <v-container fluid>
      <Alert/>
      <dialog-import-voting/>
      <v-row align='center' align-content='center' justify='center'>
        <table-import-voting/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Alert from '../../components/Alert'
import DialogImportVoting from './components/DialogImportVoting.vue'
import TableImportVoting from './components/TableImportVoting'

export default {
  components: {
    Alert,
    TableImportVoting,
    DialogImportVoting
  }
}
</script>
