<template>
  <div v-frag>
    <v-container fluid>
      <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
        <v-card class='rounded-lg' elevation='0'>
          <v-card-title class=''>
            <v-row>
              <v-col
                class='font-weight-bold'
                cols='12'
                lg='5'
                md='5'
                sm='12'
                xl='5'
                xs='12'
              >
                Import Voting
              </v-col>

              <v-col cols='12' lg='5' md='5' sm='12' xl='5' xs='12'>
                <v-text-field
                  v-model='search'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols='12' lg='2' md='2' sm='12' xl='2' xs='12'>
                <v-btn
                  block
                  class='white--text text-capitalize'
                  color='primary'
                  height='100%'
                  outlined
                  @click='openDialog(null)'
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>
              <!-- <div v-frag v-if="selected.length > 0">
                            <v-col cols="12" sm="12" xs="12" md="10" lg="10" xl="10">
                            <div v-frag>
                                <v-btn
                                @click="openDialogDelete(null)"
                                color="red"
                                active-class="red-ligth"
                                class="red--text text-capitalize"
                                outlined
                                >
                                <v-icon color="red">mdi-close-thick</v-icon>
                                <b class="red--text text-capitalize">Delete</b>
                                </v-btn>
                            </div>
                            </v-col>
                        </div> -->
            </v-row>
          </v-card-title>
          <v-data-table
            v-model='selected'
            :headers='headers'
            :items='desserts'
            :items-per-page='5'
            :loading='loading'
            :options.sync='options'
            :search='search'
            :server-items-length='totalDesserts'
            :single-select='false'
            checkboxColor='primary'
            elevation='0'
            item-key='id'
            show-select
          >
            <template v-slot:[`item.description`]='{ item }'>
              <span class='campaing' @click='openDialog(item.id)'>{{
                  item.description
                                                                  }}</span>
            </template>
            <template v-slot:[`item.percentage`]='{ item }'>
              <v-progress-linear
                :value='item.percentage'
                class='rounded-lg'
                color='primary'
                height='15'
                rounded
              >
                <strong>{{ item.percentage }}%</strong>
              </v-progress-linear>
            </template>
            <template v-slot:[`item.id`]='{ item }'>
              <div v-if='item.status == 4 || item.status == 2 || item.status == 3' v-frag>
                <v-icon
                  class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                  dense
                  @click='openDialog(item.id)'
                >
                  {{ item.status == 2 ? 'mdi-database-import' : 'mdi-pencil' }}
                </v-icon>
                <v-icon
                  class='mr-2 my-1 pa-2 red white--text rounded-sm'
                  dense
                  @click='openDialogDelete(item)'
                >
                  mdi-close-thick
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-container>
    <v-dialog v-model='dialogDelete' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Delete Import Voting
        </v-card-title>
        <v-card-subtitle>
          <b
          >You are about to delete the following item, when you unsubscribe,
           the changes corresponding to votes will be undone:</b
          >
        </v-card-subtitle>
        <!-- <v-list> -->
        <v-col cols='12'>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class='text-left'>Campaign</th>
                <th class='text-left'>File name</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for='(item, index) in itemsDelete' :key='index'>
                <td>{{ item.campaign }}</td>
                <td>{{ item.name }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='closeDialogDelete'
          >
            Cancel
          </v-btn
          >
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize white--text'
            color='warning'
            large
            @click='deleteItems'
          >
            Delete
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style='display: none'>{{ alert.response }}</div>
  </div>
</template>

<script>
import importVoting from '../../../services/importVoting'

export default {
  mounted() {
    setTimeout(() => {
      this.interval = setInterval(() => {
        this.initialLoad()
      }, 10000)
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    alert() {
      if (this.$store.getters.alert.response) {
        this.initialLoad()
      }
      return this.$store.getters.alert
    }
  },
  watch: {
    options: {
      async handler() {
        await this.initialLoad()
      },
      deep: true
    },
    search: {
      async handler() {
        this.options.search = this.search
        await this.initialLoad()
      },
      deep: true
    }
  },
  data: () => ({
    search: '',
    selected: [],
    options: {},
    totalDesserts: 0,
    interval: null,
    headers: [
      {
        text: 'Campaign',
        align: 'start',
        sortable: true,
        value: 'campaign'
      },
      {
        text: 'Description',
        align: 'start',
        sortable: true,
        value: 'description'
      },
      {
        text: 'Upload file',
        align: 'start',
        sortable: true,
        value: 'created_at'
      },
      {
        text: 'Progress',
        align: 'start',
        sortable: false,
        value: 'percentage'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status_text'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: true,
        value: 'id'
      }
    ],
    desserts: [],

    loading: false,
    dialogDelete: false,
    itemsDelete: []
  }),
  methods: {
    async initialLoad() {
      this.loading = true
      try {
        let aux = this.options
        aux.election_id = this.$route.params.campaing
        const res = await importVoting.index(aux)
        this.totalDesserts = res.totalDesserts
        this.desserts = res.desserts
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    openDialog(id = null) {
      let text = id == null ? 'New Import' : 'Edit Import'
      this.$store.dispatch('crud', {
        dialog: true,
        title: text,
        id: id,
        data: {
          campaing: this.$route.params.campaing
        }
      })
    },
    openDialogDelete(item) {
      let aux
      if (item) {
        aux = [item]
      }
      // else {
      //   aux = this.selected;
      // }

      this.itemsDelete = aux
      this.dialogDelete = true
    },
    async deleteItems() {
      try {
        let aux = []
        for (let index in this.itemsDelete) {
          aux.push(this.itemsDelete[index].id)
        }
        const res = await importVoting.delete(aux)
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialogDelete()
        // this.selected = [];
      } catch (e) {
        // empty
      }
    },
    closeDialogDelete() {
      this.itemsDelete = []
      this.dialogDelete = false
    }
  }
}
</script>
