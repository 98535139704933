import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  index: async(data) => {
    try {
      const response = await HTTP.get('importVoting', {
        headers: helper.setHeaders(),
        params: data
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getHeaders: async(id) => {
    try {
      const response = await HTTP.get(`importVoting-headers/${id}`, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  setHeaders: async(id, data) => {
    try {
      const response = await HTTP.post(
        `importVoting-headers/${id}`,
        {
          data: data
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  show: async(id) => {
    try {
      const response = await HTTP.get(`importVoting/${id}`, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  store: async(election_id, type_vote, description) => {
    try {
      const response = await HTTP.post(
        `importVoting`,
        {
          election_id: election_id,
          type_vote: type_vote,
          description: description
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  update: async(id, election_id, type_vote, description) => {
    try {
      const response = await HTTP.put(
        `importVoting/${id}`,
        {
          election_id: election_id,
          type_vote: type_vote,
          description: description
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  uploadFile: async(id, file) => {
    try {
      let formData = new FormData()
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      formData.append('file', file)
      const response = await HTTP.post(
        `importVoting-update-file/${id}`,
        formData,
        {
          headers: headers
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  // uploadFileUpdate: async (file, id) => {
  //   try {
  //     let formData = new FormData();
  //     let headers = helper.setHeaders();
  //     headers["Content-Type"] = "multipart/form-data";
  //     formData.append("file", file);
  //     const response = await HTTP.post(
  //       `importVoting-update-file/${id}`,
  //       formData,
  //       {
  //         headers: headers,
  //       }
  //     );
  //     return response.data;
  //   } catch (e) {
  //     helper.errorHandler(e.response);
  //     return e.response;
  //   }
  // },
  syncFile: async(id) => {
    try {
      const response = await HTTP.get(`importVoting-sync/${id}`, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  delete: async(data) => {
    try {
      const response = await HTTP.post(
        `importVoting-delete-items`,
        {
          data: data
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  download: async() => {
    const response = await HTTP.get('importVoting-template', {
      headers: helper.setHeaders()
    })
    location.href = response.data
    return true
  }
}
